import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import LazzyLoading from "../../src/components/common/LazzyLoading";

const PdfViewer = ({ pdfUrl, height, width }) => {
  const [docs, setDocs] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);


  const defaultStyle = {
    height: height ? height : "650px",
    width: width ? width : "100%",
    margin: '0 auto',
    border: "1px solid #dddddd",
    borderRadius: '15px',
    display: 'block',
    opacity: isLoaded ? 1 : 0,
    marginTop: '-32px'
  };

  const getDocument = async () => {
    try {
      const response = await axios.get(pdfUrl, { responseType: "blob" });

      if (!response.status === 200) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      const pdfText = response.data;

      const blob = new Blob([pdfText], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(blob);

      setDocs(fileURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDocument();
  }, [pdfUrl]);

  return (
    <div style={{ width: '100%', marginTop: 0, overflow: 'hidden' }}>
      {!isLoaded && <LazzyLoading />}
      <iframe
        width={'100%'}
        title="pdf viewer"
        src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(pdfUrl)}`}
        // src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(pdfUrl)}&toolbar=0`}
        // src={docs}
        onLoad={() => setIsLoaded(true)}
        style={defaultStyle}
        // sandbox="allow-scripts allow-same-origin allow-forms"
        // seamless
        aria-controls="false"

      ></iframe>
    </div>
  );
};

export default memo(PdfViewer);
