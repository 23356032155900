import React from 'react'
import { Box, Grid, Typography, Card, Button, CircularProgress } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { circularProgressClasses } from '@mui/material/CircularProgress'


export default function Results({ answers, setShowFeedback, setPage }) {

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    size={"170px"}
                    value={100}
                    sx={{ color: Math.round(props.value) == 0 ? '#F93060' : '#0000001A' }} // Set disabled color
                />

                <CircularProgress {...props}
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        color: '#12A53B',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },

                    }}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography textAlign={"center"} variant='body' fontWeight={700} sx={{ opacity: 0.8 }}>Score</Typography>
                    <Typography variant='h4' fontWeight={600}>{`${Math.round(props.value)}${Math.round(props.value) == 0 ? "" : "%"}`}</Typography>

                </Box>
            </Box>
        );
    }

    const calculatePercentage = (data) => {
        let marks = 0;
        for (let i = 0; i < data.length; i++) {
            marks += data[i].grade;
        }
        if (marks == 0)
            return 0

        let percentage = (marks / (data?.length * 10)) * 100
        return parseInt(percentage).toFixed(0);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    sx={{ mb: 1 }}
                    onClick={() => setShowFeedback(false)}
                    className="btn-back"
                    startIcon={<ArrowBackRoundedIcon />}
                >Back</Button>
                {
                    !!answers?.length &&
                    <Button
                        sx={{ mb: 1 }}
                        onClick={() => {
                            setPage("settings")
                            setShowFeedback(false)
                        }}
                        className="btn-back"
                    >
                        Change Audience
                    </Button>
                }


            </Box>
            {
                !answers.length &&
                <Typography sx={{ mt: 10, width: '100%' }} textAlign="center" fontWeight={600} > No Answer Submitted Yet!</Typography>
            }
            <Grid container spacing={1.3} sx={{ mt: 3, pb: 5 }}>
                {
                    !!answers.length &&
                    <>
                        <Grid item md={8.5}>
                            <Card sx={{ ...cardStyle }}>
                                {
                                    <RenderResultString
                                        percentage={calculatePercentage(answers)}
                                    // percentage={81}
                                    />
                                }
                            </Card>
                        </Grid>
                        <Grid item md={3.5}>
                            <Card sx={{ ...cardStyle, justifyContent: 'center' }}>
                                <CircularProgressWithLabel thickness={4} size="170px" variant="determinate"
                                    // value={50}
                                    value={calculatePercentage(answers)}
                                />
                            </Card>
                        </Grid>
                    </>
                }
                {
                    answers?.map((item, index) => {
                        return (
                            <Box sx={{ ...rowStyle, mt: index === 0 ? 5 : 1 }}>
                                <Box>
                                    <Typography sx={{ mt: 2, opacity: 0.5 }} fontWeight={600} variant="h6">Question</Typography>
                                    <Typography sx={{ opacity: 0.6 }}>
                                        {item?.question}
                                    </Typography>
                                    <Typography sx={{ mt: 2, opacity: 0.5 }} fontWeight={600} variant="h6">Feedback</Typography>
                                    <Typography sx={{ opacity: 0.9 }}>
                                        {item?.feedback}
                                    </Typography>
                                </Box>
                                <Box sx={{ p: '10px 26px', background: '#616DED1A', borderRadius: '10px', width: 'fit-content' }}>
                                    <Typography variant='h6' color="#616ded">{item.grade}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                }

            </Grid>
        </Box>
    )
}


const RenderResultString = ({ percentage }) => {
    const lower0 = {
        image: '/static/images/level0.svg',
        title: "You're Embarking on Your Journey",
        levelUp: ' Familiarize yourself with the basic concepts and terms of the case study.'
    }
    const lower10 = {
        image: '/static/images/level1.svg',
        title: "You're Taking Your First Strides",
        levelUp: " Start identifying the key issues or themes in the case study."
    }
    const lower20 = {
        image: '/static/images/level2.svg',
        title: "You're Gaining Momentum",
        levelUp: "Begin to analyze how these key issues impact the case scenario"
    }
    const lower30 = {
        image: '/static/images/level3.svg',
        title: "You're Building a Strong Foundation",
        levelUp: "Deepen your understanding by exploring the relationships between different elements of the case"
    }
    const lower40 = {
        image: '/static/images/level4.svg',
        title: "You're Making Significant Progress",
        levelUp: "Develop hypotheses or solutions based on your current understanding"
    }
    const lower50 = {
        image: '/static/images/level5.svg',
        title: "You're at the Midway Point of Mastery",
        levelUp: " Critically evaluate the strengths and weaknesses of your proposed solutions"
    }
    const lower60 = {
        image: '/static/images/level6.svg',
        title: "You're Solidifying Your Knowledge",
        levelUp: "Incorporate feedback or new information to refine your solutions"
    }
    const lower70 = {
        image: '/static/images/level7.svg',
        title: "You're Approaching Expertise",
        levelUp: "Begin to anticipate potential challenges or counterarguments to your solutions"
    }
    const lower80 = {
        image: '/static/images/level8.svg',
        title: "You're Emerging as an Expert",
        levelUp: "Demonstrate a comprehensive understanding by integrating complex concepts or varied perspectives"
    }
    const lower90 = {
        image: '/static/images/level9.svg',
        title: "You're Almost There!",
        levelUp: "Perfect your ability to communicate your findings clearly and persuasively"
    }
    const lower100 = {
        image: '/static/images/level10.svg',
        title: "Mastery Achieved! Congratulations!",
        levelUp: "Continue to apply your knowledge in new contexts and stay updated with the latest developments related to your case studyy"
    }
    const renderContent = () => {
        switch (true) {
            case percentage == 0:
                return lower0
            case percentage <= 10:
                return lower10
            case percentage <= 20:
                return lower20
            case percentage <= 30:
                return lower30
            case percentage <= 40:
                return lower40
            case percentage <= 50:
                return lower50
            case percentage <= 60:
                return lower60
            case percentage <= 70:
                return lower70
            case percentage <= 80:
                return lower80
            case percentage <= 90:
                return lower90
            case percentage <= 100:
                return lower100
        }
    }
    const content = renderContent()
    return (
        <>
            <img width='45%' style={{ marginBottom: '-20px' }} src={content.image} alt="" />
            <Box sx={{ pr: 3 }}>
                <Typography fontWeight={700} variant="h5">{content.title}</Typography>
                <Box sx={{ borderRadius: '5px', px: '5px', backgroundColor: '#0000001A', width: 'fit-content', my: 1 }}>
                    <Typography variant='caption' sx={{ fontWeight: 600, opacity: 0.5 }}>TO LEVEL UP</Typography>
                </Box>
                <Typography fontWeight={400} variant="caption">{content.levelUp}</Typography>
            </Box>
        </>
    )
}


const cardStyle = {
    height: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    borderRadius: '15px',
    background: '#fff',
    padding: '10px'
}

const rowStyle = {
    borderRadius: '12px',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
        background: '#616DED1A'
    }
}