import React, { useState } from "react";
import {
  Box,
  Button,
  Alert
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CommonInput from "./CommonInput";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LoadingDialog from "./LoadingDialog";

import { postNextClick } from '../../_api/next-click-submissions'
import ChatBot from "../chatbot/index";
import VideoDialog from './VideoDialog'



const ColorlibConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    display: 'none'
  },
}));


export default function VerticalStepper(
  { activeStep,
    steps,
    setVerticalStep,
    setHorizontalStep,
    horizontalStep,
    verticalStep,
    setPage,
    setShowButton,
    allSteps
  }
) {
  const [openLoading, setOpenLoading] = useState(false)
  const [message, setMessage] = useState("")
  const isLast = Boolean(steps.length - 1 == activeStep && horizontalStep === 3)

  const videoLink = (key) => {
    switch (key) {
      case "problem":
        return process.env?.REACT_APP_PROBLEM_VIDEO
      case "environment":
        return process.env?.REACT_APP_ENVIRONEMT_VIDEO
      case "people":
        return process.env?.REACT_APP_PEOPLE_VIDEO
      case "process":
        return process.env?.REACT_APP_PROCESS_VIDEO
      case "technology":
        return process.env?.REACT_APP_TECHNOLOGY_VIDEO
      case "cost":
        return process.env?.REACT_APP_COST_VIDEO
      case "budget":
        return process.env?.REACT_APP_BUDGET_VIDEO
      case "consideration":
        return process.env?.REACT_APP_CONSIDERATION_VIDEO
      case "roi":
        return process.env?.REACT_APP_ROI_VIDEO
      case "roi_realization":
        return process.env?.REACT_APP_ROI2_VIDEO
      case "prioritize":
        return process.env?.REACT_APP_PRIORITIZE_VIDEO
      case "plan":
        return process.env?.REACT_APP_PLAN_VIDEO
      case "resources":
        return process.env?.REACT_APP_RESOURCES_VIDEO
      case "timeline":
        return process.env?.REACT_APP_TIMELINE_VIDEO
      case "kpis":
        return process.env?.REACT_APP_KPIS_VIDEO
    }
  }

  const checkQuilValidation = (value) => {
    let text = value.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "")
    if (!text.trim()) return false
    return true
  }
  return (
    <Box>

      <ChatBot />
      {
        message &&
        <Alert sx={{ mb: 2 }} severity="error">{message}</Alert>
      }
      <Stepper
        orientation="vertical"

        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            {
              activeStep <= index &&
              <StepLabel
                StepIconComponent={(step) => {
                  if (!step.completed) {
                    return <div className={
                      step.active ? "step-box-v bg-secondary"
                        : "step-box-v bg-disabled"}
                    >
                      {!step.completed ? step.icon.toString().padStart(2, 0) : <CheckIcon />}
                    </div>
                  }
                }
                }
              >
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', mb: "3px" }}>
                  <Typography variant="h6">{step.label}</Typography>
                  {
                    activeStep === index && <VideoDialog link={videoLink(step?.key)} />
                  }

                </Box>
              </StepLabel>
            }
            <StepContent>
              <Typography sx={{ mb: 2 }} >{step.description}</Typography>
              <CommonInput
                value={step?.value}
                setValue={step?.setValue}
                placeholder={step.placeholder}
              />

              <Box className="flex-end" sx={{ gap: '20px', mt: 3, position: 'relative' }}>
                {
                  steps.length - 1 == activeStep &&
                  <Box className="ending-step-ball" />
                }
                <Button

                  startIcon={!(verticalStep == 0 && horizontalStep == 0) && <ArrowBackIcon />}
                  variant="outlined"
                  sx={btnHover}
                  onClick={() => {
                    if (verticalStep == 0 && horizontalStep == 0) {
                      window.history.go(-1)
                      setVerticalStep(0)
                      setHorizontalStep(0)
                      setShowButton(true)
                    } else if (verticalStep == 0 && horizontalStep != 0) {
                      setVerticalStep(allSteps[horizontalStep - 1].steps.length - 1)
                      setHorizontalStep(pre => pre - 1)
                    } else {
                      setVerticalStep(pre => pre - 1)
                    }
                  }}
                >
                  {(verticalStep == 0 && horizontalStep == 0) ? "Cancel" : "Back"}
                </Button>

                <Button variant="contained"
                  disabled={!checkQuilValidation(steps[activeStep]?.value)}
                  onClick={async () => {
                    try {
                      setOpenLoading(true)
                      await postNextClick(steps[activeStep]?.key, steps[activeStep]?.value)
                      setOpenLoading(false)
                      if (steps.length - 1 == activeStep) {
                        if (horizontalStep == 3) {
                          setPage("settings")
                          return
                        }
                        setHorizontalStep(pre => pre + 1)
                        setVerticalStep(0)
                      } else {
                        setVerticalStep(activeStep + 1)
                      }
                      setMessage("")
                    } catch (e) {
                      setMessage(e.message)
                      setOpenLoading(false)
                    }
                  }}
                  endIcon={isLast ? <DoneRoundedIcon /> : <ArrowForwardRoundedIcon />}
                  className={!checkQuilValidation(steps[activeStep]?.value) ? 'next-button' : "next-button bg-primary"}
                >
                  {
                    isLast ? "Submit" : 'Next'
                  }
                </Button>
              </Box>



            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* 
      <Box className="flex-end">
        <Button

          startIcon={!(verticalStep == 0 && horizontalStep == 0) && <ArrowBackIcon />}
          variant="outlined"
          sx={btnHover}
          onClick={() => {
            if (verticalStep == 0 && horizontalStep == 0) {
              window.history.go(-1)
              setVerticalStep(0)
              setHorizontalStep(0)
              setShowButton(true)
            } else if (verticalStep == 0 && horizontalStep != 0) {
              setVerticalStep(allSteps[horizontalStep - 1].steps.length - 1)
              setHorizontalStep(pre => pre - 1)
            } else {
              setVerticalStep(pre => pre - 1)
            }
          }}
        >
          {(verticalStep == 0 && horizontalStep == 0) ? "Cancel" : "Back"}
        </Button>

        <Button variant="contained"
          disabled={steps[activeStep]?.value.toString().trim() === ""}
          onClick={async () => {
            try {
              setOpenLoading(true)
              await postNextClick(steps[activeStep]?.key, steps[activeStep]?.value)
              setOpenLoading(false)
              if (steps.length - 1 == activeStep) {
                if (horizontalStep == 3) {
                  setPage("settings")
                  return
                }
                setHorizontalStep(pre => pre + 1)
                setVerticalStep(0)
              } else {
                setVerticalStep(activeStep + 1)
              }
              setMessage("")
            } catch (e) {
              setMessage(e.message)
              setOpenLoading(false)
            }
          }}
          endIcon={isLast ? <DoneRoundedIcon /> : <ArrowForwardRoundedIcon />}
          className={steps[activeStep]?.value.toString().trim() === "" ? 'next-button' : "next-button bg-primary"}
        >
          {
            isLast ? "Submit" : 'Next'
          }
        </Button>
      </Box> */}
      <LoadingDialog open={openLoading} setOpen={setOpenLoading} text="Please wait..." />
    </Box >
  );
}



const btnHover = {
  // position: 'absolute',
  // bottom: '50px',
  // right: { lg: '17%', md: '20%', sm: '23%' },
  fontWeight: 700,
  color: '#616ded',
  padding: '5px 20px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: '#616DED1A'
  }

}