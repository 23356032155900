import { isExpired } from "react-jwt";
const isAuthenticated = (token) => {
  if (token === undefined) {
    return false;
  }
  if (token === "NULL") {
    return false;
  }
  if (token === null) {
    return false;
  }
  return !isExpired(token);
};
export default isAuthenticated;
