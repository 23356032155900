import React, { useState } from 'react'
import {
    Button,
    Typography,
    Box,
    Grid,
    FormControl, RadioGroup, FormControlLabel, Radio,
    Alert
} from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';



export default function Settings(
    {
        setHorizontalStep,
        setVerticalStep,
        setPage,
        handleSubmit,
        message
    }
) {
    const [level, setLevel] = useState("C-Suite")


    const FieldLabel = ({ label }) => <Typography sx={{ opacity: 0.3, mt: 3, mb: 1 }} variant='h6'>{label}</Typography>

    return (
        <div style={{ width: '97%' }}>
            <Button onClick={() => {
                setHorizontalStep(0)
                setVerticalStep(0)
                setPage("main")
            }} sx={{ mb: 1 }} className="btn-back" startIcon={<ArrowBackRoundedIcon />} >Back</Button>

            <Box sx={{ mt: 5, p: 5, width: '100%' }}>
                <Grid container spacing={3} alignItems={"center"}>
                    <Grid md={6.5}>
                        <Typography sx={{ mb: 3 }} variant='h4' fontWeight={600}>
                            Let's Get Ready to Present!
                        </Typography>
                        <FieldLabel label="Select a Difficulty Level" />
                        <FormControl sx={{ mt: 1, ml: 2 }}>
                            <RadioGroup
                                name="dificulty_level"
                                value={level}
                                onChange={(e) => setLevel(e.target.value)}
                            >
                                <FormControlLabel value="C-Suite" control={<Radio />} label="C-Suite" />
                                <FormControlLabel sx={{ mt: 1 }} value="Management" control={<Radio />} label="Management" />
                                <FormControlLabel sx={{ mt: 1 }} value="Analyst" control={<Radio />} label="Analyst" />
                            </RadioGroup>
                        </FormControl>
                        <br />

                        {
                            message &&
                            <Alert sx={{ m: 2 }} severity="error">{message || "Something went wrong, please try again"}</Alert>
                        }
                        <Button
                            sx={{
                                mt: 9,
                                padding: '10px 20px',
                                textTransform: 'capitalize',
                                backgroundColor: '#616DED',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: '#4133C2'
                                }

                            }}
                            variant="contained"
                            endIcon={<ArrowForwardRoundedIcon />}
                            onClick={() => handleSubmit(level)}
                        >
                            Generate Slides
                        </Button>
                    </Grid>
                    <Grid md={5.5}>
                        <img width="70%" src="/static/images/level.png" alt="Level Side" />
                    </Grid>
                </Grid>
            </Box>
        </div>

    )
}