import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Container, Typography, Button, TextField, MenuItem,
    FormControl, RadioGroup, FormControlLabel, Radio, Box, Grid, Alert
} from '@mui/material'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup'

import { QuillEditor } from '../quill-editor';
import { data as industryData, businessProblems } from '../industries/industries'
import { generateCaseStudy } from '../../_api/index'
import LoadingDialog from '../common/LoadingDialog.js';


export default function GenerateWithAI() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [openloadingModal, setOpenLoadingModal] = useState(false)
    const [message, setMessage] = useState("")

    const formik = useFormik({
        initialValues: {
            industry_name: '',
            business_problem: '',
            dificulty_level: 'High School',
            status: 'Public',
            additional_info: ''
        },
        validationSchema: Yup.object({
            industry_name: Yup.string().required("Please select one industry name"),
            business_problem: Yup.string().required("Please select one business problem"),
            dificulty_level: Yup.string().required("Please choose one dificulty level"),
            status: Yup.string().required("Please choose one visibility status"),
        }),
        onSubmit: async (values) => {
            setOpenLoadingModal(true)
            setMessage("")
            try {
                let res = await generateCaseStudy(values)
                setOpenLoadingModal(false)
                queryClient.invalidateQueries("get-case-studies")
                navigate(`/case-study-steps?file_link=${res.replace(process.env.REACT_APP_S3_BUCKET_LINK, "")}&type=ai-generated`)
            } catch (err) {
                setOpenLoadingModal(false)
                setMessage(err.response.data.detail || "Something went wrong, please try again later")
            }
        }
    })

    const FieldLabel = ({ label }) => <Typography sx={{ opacity: 0.3, mt: 3, mb: 1 }} variant='h6'>{label}</Typography>

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ pb: 5 }}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        sx={btnHover}
                        onClick={() => window.history.go(-1)}
                    >
                        Back
                    </Button>
                    <Typography variant='h4' fontWeight={600}>
                        Generate Case Study
                    </Typography>

                    {
                        message &&
                        <Alert sx={{ my: 2 }} severity="error">{message}</Alert>
                    }
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <FieldLabel label="Choose an Industry" />
                            <TextField
                                name="industry_name"
                                value={formik.values.industry_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.industry_name && formik.errors.industry_name)}
                                helperText={formik.touched.industry_name && formik.errors.industry_name}
                                select
                                fullWidth
                                InputProps={{ sx: { borderRadius: '10px' } }}
                            >
                                {industryData.map(item => <MenuItem value={item.title}>{item.title}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item md={6}>
                            <FieldLabel label="Choose a Business Problem" />
                            <TextField
                                name="business_problem"
                                value={formik.values.business_problem}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.business_problem && formik.errors.business_problem)}
                                helperText={formik.touched.business_problem && formik.errors.business_problem}
                                select
                                fullWidth
                                InputProps={{ sx: { borderRadius: '10px' } }}
                            >
                                {businessProblems.map(item => <MenuItem value={item}>{item}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item md={6}>
                            <FieldLabel label="Select a Difficulty Level" />
                            <FormControl sx={{ mt: 1 }}>
                                <RadioGroup
                                    name="dificulty_level"
                                    value={formik.values.dificulty_level}
                                    onChange={formik.handleChange}
                                >
                                    <FormControlLabel value="High School" control={<Radio />} label="High School" />
                                    <FormControlLabel sx={{ mt: 1 }} value="College" control={<Radio />} label="College" />
                                    <FormControlLabel sx={{ mt: 1 }} value="Professional" control={<Radio />} label="Professional" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FieldLabel label="Visibility Status" />
                            <FormControl sx={{ mt: 1 }}>
                                <RadioGroup
                                    name="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                >
                                    <FormControlLabel sx={{ mt: 1 }} value="Public" control={<Radio />} label="Public" />
                                    <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FieldLabel label="Additional Information" />
                            <QuillEditor
                                placeholder="Write something"
                                sx={{ height: '200px' }}
                                name="industry_name"
                                value={formik.values.additional_info}
                                onChange={(e) => { formik.setFieldValue("additional_info", e) }}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            disabled={formik.isSubmitting}
                            variant="contained"
                            endIcon={<AutoFixHighIcon />}
                            type='submit'
                            sx={{
                                ml: 2,
                                mt: 3,
                                fontWeight: 700,
                                padding: '12px 30px',
                                textTransform: 'capitalize',
                                backgroundColor: '#616DED',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: '#4133C2'
                                }

                            }}
                        >Generate</Button>
                    </Box>
                </Box>
            </form>
            <LoadingDialog open={openloadingModal} setOpen={setOpenLoadingModal} text={"Generating..."} />
        </>
    )
}



const btnHover = {
    mb: 2,
    fontWeight: 700,
    color: '#616ded',
    padding: '10px 20px',
    textTransform: 'capitalize',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#616DED1A'
    },

}