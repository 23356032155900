import React, { useState } from 'react'
import {
    Box, Typography, Divider, TablePagination,
} from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function List({ data }) {
    const [tablePage, setTablePage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const applyPagination = (skillSections) =>
        skillSections?.slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage);

    const handlePageChange = (event, newPage) => {
        setTablePage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0)
    };

    if (!data?.length)
        return <Box sx={{
            width: '100%', textAlign: 'center', fontWeight: 500, mt: 5, borderBottom: '1px solid #ddd', opacity: 0.5, pb: 3
        }}
        >No Results Found</Box>
    return (
        <Box sx={{ width: '100%', mt: 5 }}>
            {
                applyPagination(data)?.map(item => {
                    return (
                        <>
                            <Box
                                component={Link}
                                to={`/case-study-steps?file_link=${item.Link.replace(process.env.REACT_APP_S3_BUCKET_LINK, "")}&pdf_name=${item.Name}`}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '5px',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: '#17161B',
                                    '&:hover': {
                                        backgroundColor: '#616DED0D'
                                    }
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px', width: '40%' }}>
                                    <img src={"static/images/file-icon.png"} alt="" />
                                    <Typography fontWeight={600}>{item.Name}</Typography>
                                </Box>
                                <Typography sx={{ width: '30%' }} textAlign={"left"}>{item.Bussiness_Problem}</Typography>
                                <Typography>
                                    {moment(item?.CreatedAt).format("ll")}
                                </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                        </>
                    )
                })
            }
            <TablePagination
                component="div"
                count={data?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={tablePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box>
    )
}
