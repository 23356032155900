import { useState, useEffect } from 'react'
import { Box, Button, Typography, Grid, TextField, Radio, RadioGroup, FormControlLabel, MenuItem, FormControl } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PdfViewer from '../PdfViewer'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import toast from 'react-hot-toast'
import { useFormik } from 'formik';
import * as Yup from 'yup'

import { saveCaseStudy } from '../../_api/index'
import LoadingDialog from '../common/LoadingDialog';
import { data as industryData, businessProblems } from '../industries/industries'
import { uploadCaseStudy, statAnalysis } from '../../_api/index'

const CaseStudy = ({ showButton, setShowButton, fileUrl, setPage, type, pdf_name }) => {
  const [showIframe, setShowIframe] = useState(true)
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const formik = useFormik({
    initialValues: {
      industry_name: '',
      business_problem: '',
      status: 'Public',
    },
    validationSchema: Yup.object({
      industry_name: Yup.string().required("Please select one industry name"),
      business_problem: Yup.string().required("Please select one business problem"),
      status: Yup.string().required("Please choose one visibility status"),
    }),
    onSubmit: async (values) => {
      try {
        setOpenLoadingDialog(true)
        let res = await uploadCaseStudy({ ...values, file_url: process.env.REACT_APP_S3_BUCKET_LINK + fileUrl })
        setOpenLoadingDialog(false)
        setShowButton(false)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } catch (e) {
        setOpenLoadingDialog(false)
        toast.error(e.message)
      }

    }
  })

  const FieldLabel = ({ label }) => <Typography sx={{ opacity: 0.7, mt: 3, mb: 1 }} variant='h6'>{label}</Typography>

  useEffect(() => {
    setShowIframe(false)
    setTimeout(() => {
      setShowIframe(true)
    }, [200])
  }, [showButton])
  return (
    <>
      {
        showButton &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Button
              startIcon={<ArrowBackIcon />}
              sx={btnHover}
              onClick={() => {
                setShowButton(true)
                window.history.go(-1)
              }}
            >Back</Button>
            <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
              {
                type === "ai-generated" &&
                <Button
                  disabled={disableButton}
                  onClick={async () => {
                    try {
                      setOpenLoadingDialog(true)
                      await saveCaseStudy()
                      setOpenLoadingDialog(false)
                      toast.success("Saved Successfully")
                      setDisableButton(true)
                    } catch (e) {
                      toast.error(e.message)
                      setOpenLoadingDialog(false)

                    }
                  }}
                  endIcon={<SaveAltIcon />}
                  sx={{ ...btnHover }}
                >Save Case Study</Button>
              }

              <Button
                disabled={!fileUrl}
                onClick={async () => {
                  if (!type && pdf_name) {
                    setOpenLoadingDialog(true)
                    await statAnalysis(process.env.REACT_APP_S3_BUCKET_LINK + fileUrl, pdf_name);
                    setOpenLoadingDialog(false)
                  }
                  if (type === "upload") {
                    formik.handleSubmit()
                  } else {
                    setShowButton(false)
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }

                }}
                className="start_btn"
                variant='contained'
                endIcon={<ArrowForwardIcon />}
              >
                Start Analysis
              </Button>
            </Box>
          </Box>
          {
            type === "upload" &&
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={6}>
                <Grid item md={4}>
                  <FieldLabel label="Choose an Industry" />
                  <TextField
                    name="industry_name"
                    value={formik.values.industry_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.industry_name && formik.errors.industry_name)}
                    helperText={formik.touched.industry_name && formik.errors.industry_name}
                    select
                    fullWidth
                    InputProps={{ sx: { borderRadius: '10px' } }}
                  >
                    {industryData.map(item => <MenuItem value={item.title}>{item.title}</MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item md={4}>
                  <FieldLabel label="Choose a Business Problem" />
                  <TextField
                    name="business_problem"
                    value={formik.values.business_problem}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.business_problem && formik.errors.business_problem)}
                    helperText={formik.touched.business_problem && formik.errors.business_problem}
                    select
                    fullWidth
                    InputProps={{ sx: { borderRadius: '10px' } }}
                  >
                    {businessProblems.map(item => <MenuItem value={item}>{item}</MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item md={4}>
                  <FieldLabel label="Visibility Status" />
                  <FormControl sx={{ mt: 0 }}>
                    <RadioGroup
                      row
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel sx={{ mt: 1 }} value="Public" control={<Radio />} label="Public" />
                      <FormControlLabel value="Private" control={<Radio />} label="Private" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          }
        </>
      }
      <Box className="casestudy_card" sx={{ width: '100%' }}>
        <Box sx={{ position: 'relative', width: '100%' }}>
          {fileUrl ?
            <PdfViewer
              pdfUrl={process.env.REACT_APP_S3_BUCKET_LINK + fileUrl}
              height={"95vh"}
            />
            :
            <>
              <Typography sx={{ fontSize: showButton ? '12.5rem' : '5rem' }} className="casestudy_text">Case </Typography>
              <Typography sx={{ fontSize: showButton ? '12.5rem' : '5rem' }} className="casestudy_text">Study</Typography>
            </>
          }
        </Box>
      </Box>
      <LoadingDialog open={openLoadingDialog} setOpen={setOpenLoadingDialog} text={"Please wait..."} />
    </>
  );
};

export default CaseStudy;

const btnHover = {
  fontWeight: 700,
  color: '#616ded',
  padding: '10px 20px',
  textTransform: 'capitalize',
  borderRadius: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#616DED1A'
  },

}