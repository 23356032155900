import React, { useState, useEffect, useRef, useCallback } from 'react'
import Webcam from "react-webcam";
import { Button, Box, Alert, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { uploadS3Media } from '../../_api/S3Services'
import { gradeAnswer } from '../../_api/index'
import LoadingDialog from '.././common/LoadingDialog'

export default function CameraRecorder({ answers, setAnswers, index, question, questions, setIndex, setIsPresenting }) {

    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState("")

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        setTimer(0);
        setIsRunning(true);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: isSafari ? "video/mp4" : "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setIsRunning(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleStateUpdate = () => {
        setMessage("")
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });

            // Convert blob to buffer
            const reader = new FileReader();

            reader.onloadend = async () => {
                const arrayBuffer = reader.result;
                const uint8Array = new Uint8Array(arrayBuffer);
                setLoadingMessage("Uploading Video...")
                setIsLoading(true)
                const file_url = await uploadS3Media(uint8Array, "Uploading Video...", "", uuidv4(), true)
                try {
                    setLoadingMessage("Submitting Answer...")
                    const res = await gradeAnswer(question, file_url)
                    let tempAnswers = answers
                    tempAnswers.push({
                        question,
                        submittedAnswer: file_url,
                        feedback: res.feedback,
                        grade: res.grade,
                    })
                    setAnswers([...tempAnswers])
                    setTimer(0)
                    setRecordedChunks([])
                    setIsLoading(false)
                    setLoadingMessage("")
                    toast.success("Submitted")
                    if (questions?.length - 1 !== index) {
                        setIndex(index + 1)
                    }
                    setIsPresenting(false)
                } catch (err) {
                    setIsLoading(false)
                    setLoadingMessage("")
                    toast.dismiss()
                    toast.error(err?.response?.data?.detail || "Something went wrong, please try again.")
                    setMessage(err?.response?.data?.detail || "Something went wrong, please try again.")
                }

            };

            reader.onerror = (error) => {
                console.error("Buffer Error: ", error);
            };

            reader.readAsArrayBuffer(blob);
        }
    }
    useEffect(() => {
        if (timer >= 30000) {
            handleStopCaptureClick()
        }
    }, [timer])

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 10 milliseconds
            }, 1000);
        }

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [isRunning]);

    const videoConstraints = {
        width: "550",
        height: "420",
        facingMode: "user",
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        <>
            <LoadingDialog open={isLoading} text={loadingMessage} />
            <div className="web-cam">
                <Webcam
                    style={{ borderRadius: '15px' }}
                    ref={webcamRef}
                    audio={true}
                    muted
                    mirrored
                    width="100%"
                    videoConstraints={videoConstraints}
                />

                <div
                    className="cam-stream-time"
                    style={{ backgroundColor: capturing ? "#ff2525" : "#ccc" }}
                >
                    {formatTime(timer)}
                </div>
                <div className="cam-buttons">
                    {capturing ? (
                        <>
                            <button
                                style={{ cursor: "pointer" }}
                                className="cam-stop"
                                onClick={handleStopCaptureClick}
                            >
                                <div className="stop-icon"></div>
                            </button>
                        </>
                    ) : (
                        <button
                            style={{ cursor: "pointer" }}
                            className="cam-start"
                            onClick={handleStartCaptureClick}
                        ></button>
                    )}
                </div>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', mt: 2 }}>
                <Typography sx={{ opacity: 0.5 }}>
                    Record a video max 30 seconds
                </Typography>
                <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    <Button
                        disabled={(!recordedChunks.length && capturing) || timer == 0}
                        sx={{ borderRadius: '10px', textTransform: 'capitalize' }}
                        variant='outlined'
                        onClick={() => {
                            setTimer(0)
                            setRecordedChunks([])
                        }}
                    >
                        Retake
                    </Button>
                    <Button
                        disabled={(!recordedChunks.length && capturing) || timer == 0}
                        sx={{ borderRadius: '10px', textTransform: 'capitalize', backgroundColor: '#616ded' }}
                        variant='contained' onClick={handleStateUpdate}>
                        Submit Answer
                    </Button>
                </Box>

            </Box>

            {
                message &&
                <Alert sx={{ m: 2 }} severity="error">{message}</Alert>
            }
        </>
    )
}
