import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid, Button } from "@mui/material";
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CaseStudy from '../casestudy/CaseStudy';
import HorizantalStepper from "../common/HorizantalStepper";
import VerticalStepper from "../common/VerticalStepper";
import Settings from '../presentaion-settings/index'
import Slides from '../presentaion-settings/Slides';
import Presentation from '../presentaion-settings/Presentation';
import { createSlides } from '../../_api/index'
import LoadingDialog from '../common/LoadingDialog';

const CaseStudy1 = () => {

  const location = useLocation();
  const file_link = new URLSearchParams(location.search).get('file_link');
  const type = new URLSearchParams(location.search).get('type');
  const pdf_name = new URLSearchParams(location.search).get('pdf_name');

  const [verticalStep, setVerticalStep] = useState(0)
  const [horizontalStep, setHorizontalStep] = useState(0)
  const [showButton, setShowButton] = useState(true)
  const [page, setPage] = useState('main')
  const [problem, setProblem] = useState("")
  const [environment, setEnvironment] = useState("")
  const [people, setPeople] = useState("")
  const [process, setProcess] = useState("")
  const [technology, setTechnology] = useState("")
  const [cost, setCost] = useState("")
  const [capitalBudget, setCapitalBudget] = useState("")
  const [consideration, setConsideration] = useState("")
  const [roi, setRoi] = useState("")
  const [roi2, setRoi2] = useState("")
  const [prioritize, setPrioritize] = useState("")
  const [plan, setPlan] = useState("")
  const [resources, setResources] = useState("")
  const [timeline, setTimeline] = useState("")
  const [kpis, setKpis] = useState("")
  const [fileUrl, setFileUrl] = useState(file_link)
  const [slides, setSlides] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")

  const handleSubmit = async (level) => {
    setIsLoading(true)
    setMessage("")
    try {
      let res = await createSlides(level)
      toast.success("Slides Created")
      setSlides(res.data?.map((item, index) => ({ link: item, index, })))
      setPage("slides")
      setIsLoading(false)
    } catch (err) {
      toast.error(err.response?.data.detail)
      setMessage(err.response?.data.detail)
      setIsLoading(false)
    }
  }

  const allSteps = [
    {
      steps: [
        {
          label: "Problem",
          description:
            "Identify and describe the main issue that needs to be solved.",
          value: problem,
          setValue: setProblem,
          placeholder: "Example: High energy costs in a household.",
          key: 'problem',
        },
        {
          label: "Environment",
          description:
            "Examine the setting or context where the problem exists.",
          value: environment,
          setValue: setEnvironment,
          placeholder: "Example: The household includes older appliances and traditional light bulbs.",
          key: 'environemnt',
        },
      ]
    },
    {
      steps: [
        {
          label: "People",
          description:
            "Consider who is affected by the problem and who will be part of the solution.",
          value: people,
          setValue: setPeople,
          placeholder: "Example: All family members, including those responsible for paying bills.",
          key: 'people',
        },
        {
          label: "Process",
          description:
            "Outline the steps or procedures involved in solving the problem.",
          value: process,
          setValue: setProcess,
          placeholder: "Example: Identifying energy-efficient appliances, creating a budget plan, and replacing old appliances.",
          key: 'process',
        },
        {
          label: "Technology",
          description:
            "Explore technological tools or solutions that can be used.",
          value: technology,
          setValue: setTechnology,
          placeholder: "Example: Using smart thermostats and LED lighting.",
          key: 'technology',
        },
        {
          label: "Cost",
          description:
            "Estimate the expenses involved in implementing the solution.",
          value: cost,
          setValue: setCost,
          placeholder: "Example: Calculating the cost of purchasing new energy-efficient appliances.",
          key: 'cost',
        },
        {
          label: "Budget Type (Ops or Capital)",
          description:
            "Financial plan for day-to-day expenses or long-term investments",
          value: capitalBudget,
          setValue: setCapitalBudget,
          placeholder: "Example: Operational budget for monthly energy bills; Capital budget for one-time purchases of new appliances.",
          key: 'budget',
        },
        {
          label: "Considerations",
          description:
            "What other projects are going on?",
          value: consideration,
          setValue: setConsideration,
          placeholder: "Example: Budget limitations and the availability of energy-efficient technology.",
          key: 'considerations',
        },
      ]
    },
    {
      steps: [
        {
          label: "ROI (Return on Investment)",
          description:
            "Calculate the Return on Investment.",
          value: roi,
          setValue: setRoi,
          placeholder: "Example: Savings on energy bills over time compared to the cost of new appliances.",
          key: 'roi',
        },
        {
          label: "ROI Realization",
          description:
            "Determine how and when the benefits of the solution will be achieved.",
          value: roi2,
          setValue: setRoi2,
          placeholder: "Example: Measuring monthly energy bill reductions after installing new appliances.",
          key: 'roi_realization',
        },
      ]
    },
    {
      steps: [
        {
          label: "Prioritize",
          description: "Decide which solutions or aspects are the most important and should be addressed first.",
          value: prioritize,
          setValue: setPrioritize,
          placeholder: "Example: Replacing the most energy-consuming appliances first, like the refrigerator and heating system.",
          key: 'prioritise',
        },
        {
          label: "Plan",
          description:
            "Develop a detailed plan for implementing the chosen solution.",
          value: plan,
          setValue: setPlan,
          placeholder: "Example: A year-long plan to gradually replace all old appliances with energy-efficient ones.",
          key: 'plan',
        },
        {
          label: "Resources",
          description:
            "Identify the materials, tools, and human resources needed for the solution.",
          value: resources,
          setValue: setResources,
          placeholder: "Example: Budget for new appliances, information on energy efficiency, and installation services.",
          key: 'resources',
        },
        {
          label: "Timeline",
          description:
            "Establish a schedule for when different stages of the solution will be completed.",
          value: timeline,
          setValue: setTimeline,
          placeholder: "Example: Replace the heating system before winter, and the refrigerator in the next six months.",
          key: 'timeline',
        },
        {
          label: "KPIs",
          description:
            "Define Key Performance Indicators to measure the success of the solution.",
          value: kpis,
          setValue: setKpis,
          placeholder: "Example: Reduction in energy consumption by 25% within the first year.",
          key: 'kpis',
        },
      ]
    },

  ];

  const handlePage = () => {
    switch (page) {
      case 'main':
        return <Grid container spacing={3}>
          <Grid item sm={12}>
            {
              !showButton &&
              <Button
                startIcon={<ArrowBackIcon />}
                sx={btnHover}
                onClick={() => {
                  setShowButton(true)
                  window.history.go(-1)
                }}
              >Back</Button>
            }
          </Grid>
          <Grid item sm={showButton ? 12 : 6}>
            {/* <CommonCaseStudy /> */}
            <CaseStudy pdf_name={pdf_name} type={type} setFileUrl={setFileUrl} fileUrl={fileUrl} showButton={showButton} setShowButton={setShowButton} setPage={setPage} />
          </Grid>
          {
            !showButton &&
            <Grid item xs={showButton ? 0 : 6}>
              <Box className="casesudy_summary_box">
                <Box className="summary_text_box">
                  <Typography className="summary_text">Case Study Summary</Typography>
                </Box>

                <HorizantalStepper setVerticalStep={setVerticalStep} setHorizontalStep={setHorizontalStep} activeStep={horizontalStep} />
                <Box display="flex" justifyContent="center">
                  <Box width="90%">
                    <VerticalStepper
                      horizontalStep={horizontalStep}
                      setHorizontalStep={setHorizontalStep}
                      steps={allSteps[horizontalStep].steps}
                      activeStep={verticalStep}
                      setVerticalStep={setVerticalStep}
                      verticalStep={verticalStep}
                      setPage={setPage}
                      setShowButton={setShowButton}
                      allSteps={allSteps}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          }
        </Grid>
      case 'settings':
        return <Settings
          handleSubmit={handleSubmit}
          setHorizontalStep={setHorizontalStep}
          setVerticalStep={setVerticalStep}
          setPage={setPage}
          isLoading={isLoading}
          message={message}
        />
      case 'slides':
        return <Slides slides={slides} setPage={setPage} setSlides={setSlides} />
      case 'presentation':
        return <Presentation slides={slides} setPage={setPage} />
    }
  }

  useEffect(() => {
    if (horizontalStep > 3) {
      setPage("settings")
    }
  }, [horizontalStep])
  return (
    <Box className="casestudy_layout">
      {handlePage()}
      <LoadingDialog open={isLoading} setOpen={setIsLoading} text="Generating Slides..." />
    </Box>
  );
};

export default CaseStudy1;

const btnHover = {
  fontWeight: 700,
  marginTop: "-20px",
  color: '#616ded',
  padding: '10px 20px',
  textTransform: 'capitalize',
  borderRadius: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#616DED1A'
  },

}