import React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CheckIcon from "@mui/icons-material/Check";

const ColorlibConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: "1px",
    backgroundColor: "#D6D6D6",
    borderRadius: "0.75rem",
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#12A53B",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#12A53B'
    },
  },
}));

const steps = [
  "State the <br/> Problem",
  "Propose <br/> Solutions",
  "Realize <br/> Value",
  "Implement <br/> & Measure",
];

function CustomStepper({ activeStep, setHorizontalStep, setVerticalStep }) {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(step) =>
              <div
                onClick={() => {
                  if (activeStep > index) {
                    setHorizontalStep(index)
                    setVerticalStep(0)
                  }
                }}
                className={
                  step.completed ? "step-box bg-success"
                    : step.active ? "step-box bg-secondary"
                      : "step-box bg-disabled"}
              >
                {!step.completed ? step.icon.toString().padStart(2, 0) : <CheckIcon />}
              </div>}
          >
            <span
              style={{ marginTop: '-8px', display: 'flex', justifyContent: 'center' }} dangerouslySetInnerHTML={{ __html: label }} />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default CustomStepper;
