import React, { useState } from "react";
import { Link } from 'react-router-dom'
import AppBar from "@mui/material/AppBar";
import { Toolbar, IconButton, Box, Container, Dialog, DialogActions, Typography, Button } from "@mui/material";
import Logo from "../../assets/img/logo.gif";
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <AppBar className="appbar_container">
        <Toolbar className="toolbar_container">
          <Container sx={{ maxWidth: { xl: 'xl', lg: 'lg' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
              <IconButton sx={{ color: 'white', borderRadius: '8px', height: 'fit-content' }}
                onClick={() => setOpen(true)}
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: "15px",
          },
        }}
      >
        <Box sx={{ mt: 5, borderRadius: '15px', textAlign: 'center' }}>
          <Typography>Are you sure to logout ?</Typography>
        </Box>
        <DialogActions>
          <Button sx={btnHover} onClick={() => setOpen(false)}>No</Button>
          <Button
            variant="contained"
            className="btn-submit"
            onClick={() => {
              sessionStorage.removeItem("token")
              window.location.reload()
            }}>Yes</Button>
        </DialogActions>

      </Dialog >
    </>
  );
};

export default Navbar;

const btnHover = {
  fontWeight: 700,
  color: '#616ded',
  padding: '5px 20px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: '#616DED1A'
  }

}
