import React from 'react'
import { QuillEditor } from '../quill-editor'
const CommonInput = ({ value, setValue, placeholder }) => {
  return (
    <>
      <QuillEditor
        value={value}
        onChange={(e) => setValue(e)}
        placeholder={placeholder}
        sx={{ height: '200px' }}
      />
    </>
  )
}

export default CommonInput