
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Button,
    FormHelperText,
    TextField,
    IconButton,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    Container,
    Card,
    CircularProgress,
    Typography
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { login } from '../_api/index'
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            setIsLoading(true)
            try {
                let tempPayload = { email: values.email, password: values.password }
                const res = await login(tempPayload);
                if (res?.status) {
                    setIsLoading(false)
                    sessionStorage.setItem("token", res?.Token)
                    sessionStorage.setItem("adminId", res?.User?._id)
                    navigate("/")
                    window.location.reload()
                } else {
                    setIsLoading(false)
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: res.message });
                    helpers.setSubmitting(false);
                }

            } catch (err) {
                setIsLoading(false)
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <Container maxWidth="lg">
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card sx={{ p: 5, borderRadius: '15px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 4, pb: 2 }}>
                        <img src="/static/images/logo-blue.gif" alt="Logo" width="120px" />
                        <Typography sx={{ mt: 2 }} variant="body2">Log in to Case Study Analyst</Typography>
                    </Box>
                    <form onSubmit={formik.handleSubmit} >
                        <TextField
                            autoFocus
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email ? formik.errors.email : ""}
                            label="Email Address"
                            margin="normal"
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="email"
                            value={formik.values.email}
                        />

                        <TextField
                            sx={{ mt: 4 }}
                            fullWidth
                            error={Boolean(formik.touched.password && formik.errors.password)}
                            helperText={formik.touched.password ? formik.errors.password : ""}
                            label="Password"
                            margin="none"
                            name="password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                        />
                        {/* 
                        <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                            <InputLabel htmlFor="outlined-adornment-password" shrink={true}>Password</InputLabel>
                            <OutlinedInput
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                label="Password"
                                margin="none"
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}
                        {
                            formik.errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                </Box>
                            )
                        }
                        <Box sx={{ mt: 6 }}>
                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{ backgroundColor: '#616ded' }}
                            >
                                {isLoading ? <CircularProgress size={28} color="warning" thickness={2.5} /> : "Log In"}
                            </Button>
                        </Box>
                    </form >
                </Card>
            </Box>

        </Container >
    );
};
export default Login