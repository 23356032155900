
import React, { useState } from 'react'
import {
    Dialog,
    Box,
    IconButton,
    DialogTitle,
    DialogContent
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';

export default function VideoDialog({ link }) {
    const [showVideo, setShowVideo] = useState(false)
    return (
        <>
            <IconButton
                onClick={() => setShowVideo(true)}
                sx={{ borderRadius: '10px', p: '3px', color: '#616ded', '&:hover': { background: '#616DED1A' } }}>
                <HelpOutlineIcon size="small" />
            </IconButton>
            <Dialog
                open={showVideo}
                onClose={() => setShowVideo(false)}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        borderRadius: "15px",
                        overflow: "hidden",
                        p: 1,
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', py: 1 }}>
                    <IconButton onClick={() => setShowVideo(false)}>
                        <CloseIcon size="small" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: '400px', borderRadius: '15px', textAlign: 'center' }}>
                        <video style={{ borderRadius: '15px' }} controls height="100%" src={link} />
                    </Box>
                </DialogContent>

            </Dialog >
        </>
    )
}
