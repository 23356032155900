/*----------------------------------------------------------------
  Handling files upload to S3 
  ----------------------------------------------------------------*/
import AWS from "aws-sdk";
import toast from 'react-hot-toast'
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadS3Media = async (media, message, successMessage, fileName, hideSuccessMessage) => {
  // let uploadloading = toast.loading(message || "Uploading File...");
  let name = media?.name?.replace(/ /g, "-") || fileName + ".mp4"
  const params = {
    ACL: "public-read",
    Body: media,
    Bucket: S3_BUCKET,
    Key: "analyst-101/" + name,
  }
  try {
    await myBucket.putObject(params).promise()
    toast.dismiss();
    {
      !hideSuccessMessage &&
        toast.success(successMessage || "Uploaded");
    }
    return (`${process.env.REACT_APP_S3_BUCKET_LINK}${name} `)
  } catch (err) {
    if (err) {
      toast.dismiss();
      console.log("Error: ", err)
      return null
    }
  }
}

export const deleteS3Media = async (media) => {
  let toastloading = toast.loading("Deleting...");
  try {
    await myBucket.deleteObject({ Bucket: S3_BUCKET, Key: media, }).promise()
    toast.dismiss(toastloading)
    toast.success("Deleted")
    return (true)
  } catch (err) {
    toast.dismiss(toastloading)
    toast.error(err?.message)
    return null
  }
};