export const data = [
    {
        title: 'Automotive',
        image: 'static/images/automotive.svg',
    },
    {
        title: 'Financial Services',
        image: 'static/images/financial-services.svg',
    },
    {
        title: 'Healthcare',
        image: 'static/images/healthcare.svg',
    },
    {
        title: 'Retail',
        image: 'static/images/retail.svg',
    },
    {
        title: 'Telecommunications',
        image: 'static/images/telecommunication.svg',
    },
    {
        title: 'Energy',
        image: 'static/images/energy.svg',
    },
    {
        title: 'Consumer Goods',
        image: 'static/images/consumer-goods.svg',
    },
    {
        title: 'Technology',
        image: 'static/images/technology.svg',
    },
    {
        title: 'Pharmaceuticals',
        image: 'static/images/pharmaceuticals.svg',
    },
    {
        title: 'Hospitality',
        image: 'static/images/hospitality.svg',
    },
    {
        title: 'Random',
        image: 'static/images/hospitality.svg',
    },
]

export const businessProblems = [
    "New Market Entry",
    "Product Diversification",
    "Digital Transformation",
    "Supply Chain Optimization",
    "Customer Retention Strategies",
    "Mergers and Acquisitions",
    "Corporate Sustainability Initiatives",
    "Turnaround Strategy for Declining Sales",
    "Global Expansion",
    "Innovation and R&D Strategy",
    "Market Segmentation",
    "Pricing Strategy",
    "Marketing and Branding Strategy",
    "Crisis Management",
    "Random"
]