import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Grid, Box, Typography, TextField, Button, MenuItem
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

import { data } from './industries'

export default function Category() {
    const [filteredData, setFilteredData] = useState(data)

    const handleFilter = (e) => {
        const value = e.target.value;
        if (value) {
            setFilteredData(data?.filter(item =>
                item?.title?.toLowerCase().includes(value.toLowerCase())
            ))
        } else {
            setFilteredData(data)
        }
    }
    return (
        <Box sx={{ py: 2, width: '100%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="h4" fontWeight={700}>Select an Industry</Typography>
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '80%' }}>
                    <TextField
                        placeholder='Search'
                        InputProps={{
                            sx: { borderRadius: '10px' },
                            startAdornment: <SearchIcon sx={{ mr: 2 }} />
                        }}
                        onChange={handleFilter}
                        fullWidth
                    />
                </Box>
                {/* <Box sx={{ width: '19%' }}>
                    <TextField
                        fullWidth
                        select
                        label="Sort by"
                        variant="filled"
                        sx={{
                            ml: 2,
                        }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'transparent',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                                '&::after': {
                                    border: 'none'
                                },
                                '&::before': {
                                    border: 'none'
                                },
                            }
                        }}
                    >
                        <MenuItem value="latest">Latest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                    </TextField>
                </Box> */}
            </Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {
                    !filteredData?.length &&
                    <Box
                        sx={{
                            width: '100%', textAlign: 'center', fontWeight: 500, mt: 5, borderBottom: '1px solid #ddd', opacity: 0.5, pb: 3
                        }}
                    >
                        No Results Found
                    </Box>
                }
                {
                    filteredData.map((item, index) => {
                        return (
                            <Grid item md={index < 6 ? 2 : 2.4} sm={4} xs={6}>
                                <Box sx={categoryBox} component={Link} to={`/file-management?industry_name=${item.title}`}>
                                    <Box sx={{ width: '50px', height: '60px', display: 'flex', alignItems: 'center' }}>
                                        <img width="100%" src={item.image} alt="Category Box Image" />
                                    </Box>
                                    <Typography className='category-title' sx={{ mt: 5 }}>{item.title}</Typography>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box >
    )
}


const categoryBox = {
    height: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    color: '#000',
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: '#616DED1A',
    border: '1px solid #616DED0D',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: '#616DED40',
        border: '1px solid #616DED26',
        '& .category-title': {
            mt: 4,
            mb: 1,
        }
    }
}