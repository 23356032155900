import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import isAuthenticated from "../../auth/useAuth";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!isAuthenticated(sessionStorage.getItem("token"))) {
      navigate("/login")
    }
  }, [sessionStorage.getItem("token")])
  return (
    <>
      <Box className="main_box">
        <Navbar />
        <Container sx={{ maxWidth: { xl: 'xl', lg: 'lg' } }}>
          <Box className="casestudy_main_box">{children}</Box>
        </Container>
      </Box>
    </>
  );
};

export default Layout;
