import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Typography,
} from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import VideoDialog from '../common/VideoDialog'

import Slide from './Slide'


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `${grid * 2}px 0 ${grid}px 0`,
    background: isDragging ? "#616ded" : "white",
    borderRadius: '15px',
    ...draggableStyle,
    width: isDragging ? "17%" : '23%',
    height: 'fit-content'
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "rgba(97, 109, 237, 0.07)" : "white",
    padding: grid,
    width: '100%',
    display: 'flex',
    gap: '20px',
});



let col1Index = 4
export default function Slides({ setPage, slides, setSlides }) {
    const [cols, setCols] = useState([])

    function moveArrayObject(arr, fromIndex, toIndex) {
        const [removed] = arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, removed);
        return arr;
    }

    function onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const destIndex = destination.index
        const srcIndex = source.index
        if (srcIndex === destIndex) {
            return;
        }
        const tempSlides = [...slides]
        let temparray = moveArrayObject(tempSlides, srcIndex, destIndex)
        setSlides([...temparray])
    }


    function divideArrayIntoVariableChunks(arr) {
        const result = [];
        let currentIndex = 0;
        let chunkSize = parseInt(arr.length / 4);
        while (currentIndex < arr.length) {
            const remaining = arr.length - currentIndex;
            const currentChunkSize = remaining > chunkSize ? chunkSize : remaining;
            const chunk = arr.slice(currentIndex, currentIndex + currentChunkSize);
            result.push(chunk);
            currentIndex += currentChunkSize;
        }

        return result;
    }

    useEffect(() => {



        if (!!slides.length) {

            let tempCols = divideArrayIntoVariableChunks(slides)
            setCols([...tempCols])
            col1Index = 4
        }
    }, [slides])

    return (
        <Box sx={{ mt: 3, pb: 6 }}>

            <Box className="row-between" sx={{ mb: 3 }}>
                <Button onClick={() => {
                    setPage("settings")
                }} sx={{ mb: 1 }} className="btn-back" startIcon={<ArrowBackRoundedIcon />} >Back</Button>
                <Button variant="contained"
                    onClick={() => {
                        setPage('presentation')
                    }}
                    endIcon={<ArrowForwardRoundedIcon />}
                    className="btn-submit"
                >
                    Start Presentation
                </Button>
            </Box>
            <Box>
                {/* <Typography className="sub-heading" >Your Presentation</Typography> */}
                <Typography className="sub-heading" >Build your Presentation <VideoDialog link={process.env.REACT_APP_PRESENTATION_VIDEO} /></Typography>
                <Typography className="dull-text">Feel free to re-arrange any slides.</Typography>
            </Box>



            <div style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {cols.map((el, ind) => (
                        <Droppable key={ind} droppableId={`dropable${ind}`} direction='horizontal'>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {el.map((item, index) => (
                                        <Draggable
                                            key={item.index}
                                            draggableId={`${(ind * 4) + index}-dragable`}
                                            index={(ind * 4) + index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Slide slideLink={item.link} index={(ind * 4) + index + 1} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </Box>
    )
}
