import React, { useState, useEffect } from 'react'
import {
    Box, Typography, TextField, Button, MenuItem, CircularProgress
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'

import { uploadS3Media } from '../../_api/S3Services'
import { getCaseStudies } from '../../_api/index'
import List from './List'
import LoadingDialog from '../common/LoadingDialog'

export default function FileManagement() {
    const navigate = useNavigate()
    const location = useLocation();
    const industry_name = new URLSearchParams(location.search).get('industry_name');
    const [filteredData, setFilteredData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sortBy, setSortBy] = useState('latest')

    const { isLoading: caseStudiesLoading, data } = useQuery(['get-case-studies', industry_name], () => getCaseStudies(industry_name));

    useEffect(() => {
        if (!caseStudiesLoading) {
            sortData(data, sortBy)
        }
    }, [data, caseStudiesLoading])

    const handleFilter = (e) => {
        const value = e.target.value;
        if (value) {
            sortData(data?.filter(item =>
                item?.title?.toLowerCase().includes(value.toLowerCase())
            ), sortBy)
        } else {
            sortData(data)
        }
    }

    const sortData = (dataToSort, by) => {
        if (by === "latest") {
            let tempData = dataToSort.sort((a, b) => {
                return new Date(b.CreatedAt) - new Date(a.CreatedAt);
            })
            setFilteredData(tempData)
        } else {
            let tempData = dataToSort.sort((a, b) => {
                return new Date(a.CreatedAt) - new Date(b.CreatedAt);
            })
            setFilteredData(tempData)

        }
    }
    return (
        <Box sx={{ py: 2, width: '100%', backgroundColor: 'white' }}>
            <LoadingDialog
                open={isLoading || caseStudiesLoading}
                text={caseStudiesLoading ? "Loading, please wait..." : isLoading ? "Uploading, please wait..." : ""}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ width: 'fit-content', display: 'flex' }}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        sx={btnHover}
                        onClick={() => window.history.go(-1)}
                    >
                        Back
                    </Button>
                    <Typography sx={{ ml: 2 }} variant="h4" fontWeight={700}>{industry_name}</Typography>
                </Box>
                <Box sx={{ width: 'fit-content' }}>
                    <Button
                        disabled={isLoading}
                        variant="text"
                        startIcon={<CloudUploadIcon size="large" />}
                        sx={btnHover}>
                        <input
                            disabled={isLoading}
                            onChange={async (e) => {
                                const file = e.target.files[0]
                                if (file?.type === "application/pdf") {
                                    setIsLoading(true)
                                    let res = await uploadS3Media(file)
                                    setIsLoading(false)
                                    navigate(`/case-study-steps?file_link=${res.replace(process.env.REACT_APP_S3_BUCKET_LINK, "")}&type=upload`)
                                } else {
                                    toast.error("Please choose pdf file")
                                }
                            }}
                            type="file" accept=".pdf"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                opacity: 0, cursor: 'pointer'
                            }}
                        />
                        Upload
                    </Button>
                    <Button
                        disabled={isLoading}
                        variant="contained"
                        startIcon={<AutoFixHighIcon />}
                        component={Link}
                        to="/generate-with-ai"
                        sx={{
                            ml: 2,
                            fontWeight: 700,
                            padding: '10px 20px',
                            textTransform: 'capitalize',
                            backgroundColor: '#616DED',
                            cursor: 'pointer',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: '#4133C2'
                            }

                        }}
                    >Generate Case Study</Button>
                </Box>
            </Box>
            <Box sx={{ mt: 4, display: 'flex' }}>
                <Box sx={{ width: '80%' }}>
                    <TextField
                        placeholder='Search'
                        InputProps={{
                            sx: { borderRadius: '10px' },
                            startAdornment: <SearchIcon sx={{ mr: 2 }} />
                        }}
                        onChange={handleFilter}
                        fullWidth
                    />
                </Box>
                <Box sx={{ width: '19%' }}>
                    <TextField
                        value={sortBy}
                        onChange={(e) => {
                            setSortBy(e.target.value)
                            sortData(data, e.target.value)
                        }}
                        fullWidth
                        select
                        label="Sort by"
                        variant="filled"
                        sx={{
                            ml: 2,
                        }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'transparent',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                                '&::after': {
                                    border: 'none'
                                },
                                '&::before': {
                                    border: 'none'
                                },
                            }
                        }}
                    >
                        <MenuItem value="latest">Latest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                    </TextField>
                </Box>
            </Box>
            {
                caseStudiesLoading ?
                    <Box sx={{ mt: 5, textAlign: 'center' }}>
                        <CircularProgress size={28} sx={{ color: '#616ded' }} thickness={2.5} />
                    </Box>
                    :
                    <List data={filteredData} />
            }
        </Box>
    )
}

const btnHover = {
    fontWeight: 700,
    color: '#616ded',
    padding: '10px 20px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: '#616DED1A'
    }

}
