
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;
const AI_URL = process.env.REACT_APP_AI_BACKEND;


export const login = async (data) => {
    const res = await axios.post(`${API_URL}/admin/auth/login`, data, {
        'headers': {
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
            'Accept': 'application/json',
        }
    })
    return res.data;
}

export const createSlides = async (level) => {
    const res = await axios.post(`${AI_URL}/generate_ppt?adminID=${sessionStorage.getItem('adminId')}&slelected_level=${level}`)
    return res
}




//case studies

export const getCaseStudies = async (industry_name) => {
    const res = await axios.post(`${AI_URL}/get_case_studies?adminID=${sessionStorage.getItem('adminId')}&industry_name=${industry_name}`)
    return res.data
}

export const generateCaseStudy = async (data) => {
    let url = `${AI_URL}/generate_case_study?adminID=${sessionStorage.getItem('adminId')}&industry=${data.industry_name}&bussiness_problem=${data.business_problem}&difficulty_level=${data.dificulty_level}&status=${data.status}&additional_information=${data.additional_info}`
    const res = await axios.post(url)
    return res.data
}


export const saveCaseStudy = async () => {
    const res = await axios.post(`${AI_URL}/saving_case_study?adminID=${sessionStorage.getItem('adminId')}`)
    return res.data
}

export const uploadCaseStudy = async (data) => {
    let url = `${AI_URL}/uploading_case_study?adminID=${sessionStorage.getItem('adminId')}&industry=${data.industry_name}&bussiness_problem=${data.business_problem}&status=${data.status}&s3_file=${data.file_url}`
    const res = await axios.post(url)
    return res.data
}

export const statAnalysis = async (fileUrl, pdf_name) => {
    let url = `${AI_URL}/get_choosen_pdf_name_and_link?adminID=${sessionStorage.getItem('adminId')}&pdf_name=${pdf_name}&link=${fileUrl}`
    const res = await axios.post(url)
    return res.data
}

export const generateQuestions = async () => {
    let url = `${AI_URL}/generate_questions?adminID=${sessionStorage.getItem('adminId')}`
    const res = await axios.get(url)
    return res.data
}

export const gradeAnswer = async (question, video_url) => {
    let url = `${AI_URL}/grading_answers?adminID=${sessionStorage.getItem('adminId')}&video_url=${video_url.trim()}&question=${question}`
    const res = await axios.post(url)
    return res.data
}

export const chatRequest = async (message) => {
    let url = `${AI_URL}/updated_chatbot?adminID=${sessionStorage.getItem('adminId')}&query=${message}`
    const res = await axios.post(url)
    return res.data
}