import React, { useState } from 'react'
import {
    Box,
    Typography,
    Grid,
    Button,
    CircularProgress,
} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useQuery } from 'react-query'

import { generateQuestions } from '../../_api/index'
import CameraRecorder from './CameraRecorder'
import Results from './Results'
import LoadingDialog from '../common/LoadingDialog';

export default function Presentation({ setPage, slides }) {
    const [index, setIndex] = useState(0)
    const [isPresenting, setIsPresenting] = useState(false)
    const [answers, setAnswers] = useState([])
    const [showFeedback, setShowFeedback] = useState(false)
    const { isLoading, data: questions } = useQuery('get-questions', generateQuestions)

    const getSlideLink = (number) => {
        let currentSlide = slides?.filter(item => item?.index === number)
        if (currentSlide?.length) {
            return currentSlide[0]?.link
        } else {
            return "https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg"
        }
    }

    if (showFeedback) {
        return <Results setShowFeedback={setShowFeedback} answers={answers} setPage={setPage} />
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <LoadingDialog open={isLoading} text={"Loading, please wait..."} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    sx={{ mb: 1 }}
                    onClick={() => setPage('slides')}
                    className="btn-back"
                    startIcon={<ArrowBackRoundedIcon />}
                >Back</Button>
                <Button
                    disabled={questions?.length != answers?.length}
                    variant="contained"
                    sx={{ mb: 1, padding: '6px 16px', borderRadius: '10px' }}
                    onClick={() => {
                        setShowFeedback(true)
                    }}
                    className={questions?.length == answers?.length && "btn-submit"}
                >
                    View Results
                </Button>


            </Box>
            <Grid container spacing={1.3} sx={{ mt: 3, pb: 5 }}>
                <Grid item sm={6}>
                    <Box className="question-box">
                        <Typography variant="h6"> Question ({index + 1} of {questions?.length})</Typography>

                        {
                            isLoading ?
                                <Box sx={{ textAlign: 'center', mt: 3 }}>
                                    <CircularProgress size={30} color="warning" thickness={2} />
                                </Box>
                                :
                                <Typography sx={{ mt: 1, mb: 2 }}>
                                    {questions[index].question}
                                </Typography>
                        }
                    </Box>
                    <Box sx={{ mt: 1.3 }} className="slide-box">
                        <Box className="inner-box">
                            {
                                !!questions?.length &&
                                <img width=" 100%" src={getSlideLink(parseInt(questions[index]["slide number"]) - 1)} alt="Slide Image" />
                            }
                        </Box>
                        <Box className="row-between" sx={{ p: '8px 4px' }}>
                            <Button
                                disabled={index === 0 || isLoading}
                                className="btn-back"
                                startIcon={<ArrowBackRoundedIcon />}
                                onClick={() => {
                                    setIndex(index - 1)
                                    setIsPresenting(false)
                                }}
                            >Previous</Button>
                            <Button
                                disabled={isPresenting || isLoading}
                                variant="outlined"
                                className="btn-present"
                                startIcon={<img src="/static/images/presentation.svg" />}
                                onClick={() => setIsPresenting(true)}
                            >Present</Button>
                            <Button
                                disabled={(questions?.length === index + 1) || isLoading || answers?.length == index}
                                onClick={() => {
                                    setIndex(index + 1)
                                    setIsPresenting(false)
                                }}
                                className="btn-back"
                                endIcon={<ArrowForwardRoundedIcon />}
                            >Next</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box className="recording-box" sx={{ padding: '4px' }}>
                        {
                            isPresenting ?
                                <CameraRecorder
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    questions={questions}
                                    index={index}
                                    setIndex={setIndex}
                                    question={questions[index]?.question || null}
                                    setIsPresenting={setIsPresenting}
                                />
                                :
                                <Box className="inner-box">User-Recording</Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
