import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';
import Quill from 'react-quill'
// Quill 
const QuillEditorRoot = styled(Quill)(() => ({
    border: 1,
    borderColor: "#9B9FAC",
    borderRadius: "12px",
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    '& .ql-snow.ql-toolbar': {
        borderColor: "#9B9FAC",
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        color: '#9B9FAC',
        '& .ql-picker-label:hover': {
            color: '#616ded'
        },
        '& .ql-picker-label.ql-active': {
            color: '#616ded'
        },
        '& .ql-picker-item:hover': {
            color: '#616ded'
        },
        '& .ql-picker-item.ql-selected': {
            color: '#616ded'
        },
        '& button:hover': {
            color: '#616ded',
            '& .ql-stroke': {
                stroke: '#616ded'
            }
        },
        '& button:focus': {
            color: '#616ded',
            '& .ql-stroke': {
                stroke: '#616ded'
            }
        },
        '& button.ql-active': {
            '& .ql-stroke': {
                stroke: '#616ded'
            }
        },
        '& .ql-stroke': {
            // stroke: '#616ded',
        },
        '& .ql-picker': {
            color: '#616ded',
        },
        '& .ql-picker-options': {
            backgroundColor: '#ccc',
            border: 'none',
            borderRadius: '15px',
            // boxShadow: theme.shadows[10],
            // padding: theme.spacing(2),
            color: '#9B9FAC'
        }
    },
    '& .ql-snow.ql-container': {
        borderBottom: 'none',
        borderColor: '#000000BF',
        borderLeft: 'none',
        borderRight: 'none',
        flexGrow: 1,
        overflow: 'hidden',
        '& .ql-editor': {
            color: '#000',
            // fontFamily: theme.typography.body1.fontFamily,
            fontSize: '14px',
            whiteSpace: 'pre-line',
            '&.ql-blank::before': {
                // color: '#000',
                fontStyle: 'normal',
            },
        }
    }
}));
const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
    ],
}
export const QuillEditor = (props) => (
    <QuillEditorRoot modules={modules} {...props} />
);