import React from 'react'

export default function LazzyLoading() {
    return <div>
        <div className="lazzy-container">
            <h4 style={{ color: '#616ded', marginBottom: '20px' }}>Loading...</h4>
            <div className="lds-roller">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>
}
