import React from 'react'
import {
    Box,
    Typography
} from '@mui/material'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

export default function Slide(props) {
    const { index, slideLink } = props
    return (
        <Box key={index}
            sx={{ width: '100%' }}
        >
            <Box className="slide">
                <Box className="slide-inner-left">
                    <DragIndicatorRoundedIcon />
                    <Typography>{index.toString().padStart(2, 0)}</Typography>
                </Box>
                <Box className="slide-inner-right">
                    <img width="100%" height="100%" src={slideLink} alt="Slide" />
                </Box>
            </Box>
        </Box>
    )
}
