import { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    Fab,
} from '@mui/material';


import { chatRequest } from '../../_api/index'



const getRandomMsg = () => {
    // let slogan = ["Crack the code to financial success.",
    //     "Find the treasure in your finances.",
    //     "Uncover the mysteries of wealth building.",
    //     "Unlock the power of financial literacy.",
    //     "Explore the unknowns of money management.",
    //     "Open the doors to financial freedom.",
    //     "Reveal the secrets of financial abundance.",
    //     "Break the spell of financial confusion.",
    //     "Transform your finances with hidden insights.",
    //     "Unleash the magic of financial knowledge."]
    // return slogan[Math.floor(Math.random() * 10)]
    return "Hello! I am an AI assistant designed to help with questions related to evaluation.Do you have a specific question about evaluation that I can assist you with?"
}

function ChatBot() {
    const [openChatBot, setOpenChatBot] = useState(false)
    const [mouseEnter, setMouseEnter] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [msgHistory, setMsgHistory] = useState([
        { AI: getRandomMsg() }
    ])
    const [message, setMessage] = useState('')
    const [msgErr, setMsgErr] = useState('')
    const handleClose = () => setOpenChatBot(false);

    useEffect(() => {
        const scrollChatContainer = () => {
            const chatContainer = document.getElementById('chat-container');
            if (chatContainer) {
                chatContainer.scrollTo(0, chatContainer.scrollHeight);
            }
        };

        setTimeout(scrollChatContainer, 0);
    }, [msgHistory]);


    const handleRequest = async () => {
        if (isLoading) return
        if (message.length == 0) {
            setMsgErr('Please enter any query')
        }
        else {
            setMsgHistory([...msgHistory, { Human: message }])
            let _msg = message
            setMessage("")
            try {
                setLoading(true)
                const res = await chatRequest(_msg)
                console.log("Res: ", res)
                setMsgHistory([...msgHistory, { Human: message }, { AI: res || "N/A" }])
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
    };



    const LoadingCom = () => {
        return (
            <Box className="typing-animation-container">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <div style={indicationLeft}>
                </div>
            </Box>
        )
    }


    return (
        <>
            <div style={{
                position: 'fixed',
                bottom: 30,
                left: 30,
            }}>
                <Fab
                    onMouseEnter={() => setMouseEnter(true)}
                    onMouseLeave={() => setMouseEnter(false)}
                    onClick={() => setOpenChatBot(true)}
                >
                    {
                        openChatBot ?
                            <img width="100%" src="static/images/chatbot-active-icon.svg" alt="icon" />
                            :
                            mouseEnter ?
                                <img width="100%" src="static/images/chatbot-hover-icon.svg" alt="icon" />
                                :
                                <img width="100%" src="static/images/chatbot-icon.svg" alt="icon" />
                    }
                </Fab>
            </div>
            <Dialog
                open={openChatBot}
                onClose={handleClose}
                PaperProps={dialogStyles}
            >
                <DialogTitle
                    sx={titleStyles}
                >
                    Chatbot
                </DialogTitle>
                <DialogContent

                    className='custom-scrollbar'
                    sx={{
                        height: '360px',
                        backgroundColor: '#fff'
                    }}
                    id="chat-container"
                >
                    {msgHistory?.map((val, ind) => (
                        <Box key={ind}>
                            {val?.AI &&
                                <>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Box
                                                sx={responseStyle}
                                            >
                                                <div style={indicationLeft}>
                                                </div>
                                                <Typography sx={{ color: '#fff' }}>
                                                    {val.AI}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {val?.Human &&
                                <Grid container justifyContent={'end'} my={3}>
                                    <Box
                                        sx={userMessageStyle}
                                    >
                                        <div style={indicationRight}>
                                        </div>
                                        <Typography sx={{ color: '#616ded' }}>
                                            {val.Human}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                        </Box>
                    ))}
                    {!!isLoading && <>
                        <LoadingCom />
                    </>
                    }
                </DialogContent>
                <DialogActions sx={{ background: '#fff' }}>
                    <Grid container spacing={1}>
                        <Grid item md={9.4}>
                            <TextField
                                size="small"
                                InputProps={inputStyles}
                                autoFocus
                                variant='outlined'
                                fullWidth
                                value={message}
                                disabled={isLoading}
                                onChange={(e) => {
                                    if (!!msgErr) setMsgErr("")
                                    handleChange(e)
                                }}
                                onKeyDown={(e) => { if (e.keyCode === 13) { handleRequest() } }}
                            />
                        </Grid>
                        <Grid item md={2.6}>
                            <Button
                                sx={btnSendStyle}
                                onClick={handleRequest}
                                disabled={message === ""}
                                variant='contained'
                            >
                                <img src="static/images/btn-send.png" alt="" />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default ChatBot;

const btnSendStyle = {
    height: '100%',
    borderRadius: '10px',
    boxShadow: 'none',
    backgroundColor: '#616ded',
    '&:hover': {
        backgroundColor: '#4133C2'
    }
}

const inputStyles = {
    sx: {
        borderRadius: '10px',
        backgroundColor: 'rgba(97, 109, 237, 0.10)',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
    }
}

const indicationRight = {
    position: 'absolute',
    bottom: 4,
    right: -3,
    width: '15px',
    height: '15px',
    background: '#eff0fd',
    transform: 'rotate(-20deg)',
}

const indicationLeft = {
    ...indicationRight,
    left: -6,
    top: 6,
    backgroundColor: '#616ded',
    transform: 'rotate(-45deg)',
}

const responseStyle = {
    background: '#616ded',
    position: 'relative',
    borderRadius: '10px',
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: "80%",
}

const userMessageStyle = {
    background: '#eff0fd',
    position: 'relative',
    borderRadius: '10px',
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: '20px',
    display: "flex",
    maxWidth: "80%",
    justifyContent: "flex-end",
}

const titleStyles = {
    bgcolor: '#616ded',
    padding: '15px 0px',
    color: '#fff',
    width: '100%',
    textAlign: 'center'
}

const dialogStyles = {
    sx: {
        position: 'absolute',
        left: 0,
        bottom: 56,
        borderRadius: "15px",
        maxWidth: 400,
        height: 500,
        backgroundColor: 'transparent',
    },
}