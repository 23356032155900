import axios from 'axios'


const AI_URL = process.env.REACT_APP_AI_BACKEND;


export const postNextClick = async (key, data) => {
    const res = await axios.post(`${AI_URL}/get_${key}?${key}=${data}&adminID=${sessionStorage.getItem('adminId')}`)
    return res;
}

// export const postEnvironment = async (data) => {
//     const res = await axios.post(`${API_URL}/get_problem?problem=${data}&adminID=${sessionStorage.getItem('adminId')}`, data, headers)
//     return res;
// }

// export const postPeople = async (data) => {
//     const res = await axios.post(`${API_URL}/get_problem?problem=${data}&adminID=${sessionStorage.getItem('adminId')}`, data, headers)
//     return res;
// }

// export const postProcess = async (data) => {
//     const res = await axios.post(`${API_URL}/get_problem?problem=${data}&adminID=${sessionStorage.getItem('adminId')}`, data, headers)
//     return res;
// }

// export const postTechnology = async (data) => {
//     const res = await axios.post(`${API_URL}/get_problem?problem=${data}&adminID=${sessionStorage.getItem('adminId')}`, data, headers)
//     return res;
// }


// export const postCost = async (data) => {
//     const res = await axios.post(`${API_URL}/get_problem?problem=${data}&adminID=${sessionStorage.getItem('adminId')}`, data, headers)
//     return res;
// }
