
import React from 'react'
import {
    Dialog,
    Box,
    Typography
} from '@mui/material';

export default function LoadingDialog({ open, setOpen, text }) {
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                },
            }}
        >
            <Box sx={{ height: '400px', borderRadius: '15px', textAlign: 'center' }}>
                <img width="20%" src="/static/images/loading.gif" alt="Loading Image" />
                {
                    text &&
                    <Typography variant="h5" sx={{ color: '#616ded', fontWeight: 600 }}>{text}</Typography>
                }
            </Box>

        </Dialog >
    )
}

