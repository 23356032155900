// import CaseStudy from "./components/casestudy/CaseStudy";

import { Toaster } from "react-hot-toast";
import { Route, Routes } from 'react-router-dom'

import Layout from "./components/common/Layout";
import GenerateWithAI from "./components/file-management/GenerateWithAI";
import CaseStudy1 from "./components/casestudy1";
import Login from './auth/Login'
import Category from "./components/industries/index";
import FileManagement from "./components/file-management";


function App() {

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={
            <Layout>
              <Category />
            </Layout>
          } />
        <Route
          exact
          path="/file-management"
          element={
            <Layout>
              <FileManagement />
            </Layout>
          } />
        <Route
          exact
          path="/generate-with-ai"
          element={
            <Layout>
              <GenerateWithAI />
            </Layout>
          } />
        <Route
          exact
          path="/case-study-steps"
          element={
            <Layout>
              <CaseStudy1 />
            </Layout>
          } />
      </Routes>
    </>
  );
}

export default App;
